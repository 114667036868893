import React, { FC } from "react";
import {ItemWrapper, Wrapper} from "./HeroPlus2.styled";
import Hero from "Components/Hero";
import Item from "Components/Item";
import { SectionComponent } from "../types";
import {getCropData} from "../getCropData";

const HeroPlus2: FC<SectionComponent> = ({ data }) => {
  const [mainArticle, ...articles] = data.articles;

  return (
    <Wrapper>
      <Hero size={2} article={mainArticle} cropData={getCropData(mainArticle, "onebyone")} />
      <ItemWrapper>
        {articles.map((article, index) => (
            <Item
              key={index}
              size={2}
              article={article}
              cropData={getCropData(article, "onebyone")}
              showLead={true}
            />
        ))}
      </ItemWrapper>
    </Wrapper>
  );
};

export default HeroPlus2;
