import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 16px;
  padding-bottom: 31px;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
`;
export const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      grid-template-columns: 1fr;
      row-gap: 12px;  
    }
`;
